import { commonTypes } from "@app/common";
import { IExtra } from "@app/common/types";
import { QueryKeys } from "@app/room/constants/QueryKeys";
import { getRoomRateExtras } from "@app/room/queries";
import { useQueries, useQuery, UseQueryResult } from "react-query";

export const useGetRoomRateExtras = (
    propertyId: string | undefined,
    roomId: string | undefined,
    rateId: string | undefined,
    languageCode: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<IExtra[], Error>(
        [QueryKeys.RoomRateExtras, { propertyId, roomId, rateId, languageCode }],
        () => getRoomRateExtras(propertyId, roomId, rateId, languageCode),
        options
    );

export const useGetMultipleRoomRateExtras = (
    propertyId: string | undefined,
    roomRates: string[][], // [[room1Id, rate1Id], [room2Id, rate2Id], ...]
    languageCode: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQueries(
        roomRates.map((roomRate: string[]) => ({
            queryKey: [QueryKeys.RoomRateExtras, { propertyId, roomId: roomRate[0], rateId: roomRate[1], languageCode }],
            queryFn: () => getRoomRateExtras(propertyId, roomRate[0], roomRate[1], languageCode),
            enabled: options?.enabled && !!roomRates.length,
        }))
    );
