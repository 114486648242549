const languageCodeMapping: { [key: string]: string } = {
    en: "en-US",
    nl: "nl-NL",
    fr: "fr-FR",
    de: "de-DE",
    es: "es-ES",
    it: "it-IT",
    pt: "pt-PT",
    pl: "pl-PL",
    sv: "sv-SE",
    da: "da-DK",
    fi: "fi-FI",
    no: "no-NO",
    ru: "ru-RU",
    tr: "tr-TR",
    ar: "ar-SA",
    hi: "hi-IN",
    bn: "bn-IN",
    id: "id-ID",
    ja: "ja-JP",
    ko: "ko-KR",
    th: "th-TH",
    vi: "vi-VN",
    zh: "zh-CN",
    he: "he-IL",
    ro: "ro-RO",
    sk: "sk-SK",
    cs: "cs-CZ",
    hu: "hu-HU",
    el: "el-GR",
    ta: "ta-IN",
};

export const mapDetectedLanguage = (detectedLanguageCode: string) =>
    detectedLanguageCode in languageCodeMapping ? languageCodeMapping[detectedLanguageCode] : detectedLanguageCode;
