import { FullScreenIcon, LoadingDots, PriceableItemPrice } from "@app/common";
import { Flex } from "@app/componentLibrary";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { UtilsBrowserDetect } from "@hotelchamp/common";
import { Button } from "@ui";
import React, { Ref } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { useBookingEngineStateContext } from "../hooks";

export interface IWidgetFloatingTriggerProps {
    style?: React.CSSProperties;
    className?: string;
    offset?: {
        x: number;
        y: number;
    };
    onClickOpen: () => void;
    onClickClose: () => void;
}

const baseContainerStyle: React.CSSProperties = {
    position: "fixed",
    bottom: "0px",
    right: "0px",
};

const baseWidgetStyle: React.CSSProperties = {
    // backgroundColor: "lightgrey",
};

const defaultOffset = UtilsBrowserDetect.isMobile() ? { x: 8, y: 8 } : { x: 16, y: 12 };

const EMPTY_BOOKING_STATE = {};

export const WidgetFloatingTrigger = React.forwardRef((props: IWidgetFloatingTriggerProps, ref: Ref<HTMLDivElement>) => {
    const { getState: getBookingEngineState, getTotalPrice, isLoading } = useBookingEngineStateContext();
    const { t } = useTranslation("common");
    const { bookingState, activeStep, property } = getBookingEngineState();
    const { bookings } = bookingState || EMPTY_BOOKING_STATE;
    const firstBooking = bookings?.[0];
    const firstRoomImage = firstBooking ? firstBooking.room?.images[0].path || null : null;
    const totalDiscountedPrice = getTotalPrice({ discounted: true });
    const totalOriginalPrice = getTotalPrice({ discounted: false });
    const showTotalPrice = (activeStep === "summary" || activeStep === "checkout") && !isLoading;
    const buttonTitle = activeStep === "thank-you" ? t("viewYourBooking") : t("continueYourBooking");

    // todo - temporary solution, later it will be moved and become part of the property UI Config settings
    const isPropertyTheHotelCasa = property?.id === "9ca9663a-653f-413e-a9fd-13886f7eb8eb";

    return (
        <div
            data-testid="floating-trigger-btn"
            {...props}
            style={{ ...baseContainerStyle, ...(props.style || {}) }}
            className={twMerge(
                "w-fit",
                props.className,
                `px-[${props.offset?.x || defaultOffset.x}px]`,
                `py-[${props.offset?.y || defaultOffset.y}px]`
            )}
            ref={ref}
        >
            {isPropertyTheHotelCasa && (
                <div className="flex flex-row-reverse pb-1">
                    <Button
                        onClick={props.onClickClose}
                        size="icon"
                        variant="outline"
                        className="bg-[#171717]/30 text-white border-0 rounded-md"
                    >
                        <XMarkIcon width={16} className="cursor-pointer" />
                    </Button>
                </div>
            )}
            <Flex
                style={baseWidgetStyle}
                justifyContent="start"
                className={twMerge(
                    "rounded-md hover:drop-shadow-xl overflow-hidden bg-primary hover:bg-primary/90 text-white cursor-pointer transition-colors"
                )}
                onClick={props.onClickOpen}
            >
                {firstRoomImage && (
                    <div className="overflow-hidden">
                        <img
                            data-testid={`room-${firstBooking.id}-img`}
                            src={firstRoomImage}
                            alt="room"
                            className="w-24 h-[54px] rounded-l-md object-cover"
                        />
                    </div>
                )}
                <Flex flexDirection="col" justifyContent="start" alignItems="start" className="my-1.5 mx-2 gap-1">
                    <Flex className="gap-x-2">
                        <div className="text-sm font-medium leading-[17px]">{buttonTitle}</div>
                        <FullScreenIcon className="w-4 h-4 fill-white" />
                    </Flex>
                    {isLoading && <LoadingDots className="fill-white" />}
                    {showTotalPrice ? (
                        <div>
                            <PriceableItemPrice
                                className="font-bold"
                                moneyClassName="text-white text-xs"
                                outdatedMoneyClassName="text-white/60 text-xs font-normal"
                                outdatedMoneyStrikeThroughClassName="border-t-white/50 border-b-white/50"
                                item={totalOriginalPrice}
                                discountedPrice={totalDiscountedPrice}
                            />
                        </div>
                    ) : null}
                </Flex>
            </Flex>
        </div>
    );
});
