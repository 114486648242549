import { availabilityTypes } from "@app/availability";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Flex } from "@ui";
import { addMonths, format, parseISO, setMonth, setYear, subMonths } from "date-fns";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CalendarSelection } from "./CalenderSelection";
import { SelectableDateTypes } from "./constants/SelectableDateTypes";

interface IDateRangeSelectorProps {
    value?: any;
    onChange?: (event: any) => void;
    selectableDates?: SelectableDateTypes;
    availability?: availabilityTypes.ICalendarAvailability;
    currency?: string;
}

/**
 * Util to ensure that the passed value is a valid date otherwise returns undefined
 * valid Date instance of an empty string
 *
 * @param value
 * @returns
 */
const ensureDateOrUndefined = (value: any): Date | undefined => (!!value && typeof value === "string" ? parseISO(value) : value);

export const DateRangeSelector = forwardRef(
    ({ onChange, value, selectableDates, availability, currency }: IDateRangeSelectorProps, ref: any) => {
        const { t } = useTranslation(["common"]);
        const [fromDate, setFromDate] = useState<Date | undefined>(ensureDateOrUndefined(value?.fromDate));
        const [toDate, setToDate] = useState<Date | undefined>(ensureDateOrUndefined(value?.toDate));
        const [activeDate, setActiveDate] = useState(new Date());

        useEffect(() => {
            if (fromDate && fromDate.getMonth() !== activeDate.getMonth()) {
                const nextActiveDate = setYear(setMonth(activeDate, fromDate.getMonth()), fromDate.getFullYear());

                setActiveDate(nextActiveDate);
            }
        }, [value.fromDate]);

        const onDateClick = (date: any) => {
            setFromDate(ensureDateOrUndefined(date.startSelectedDate));
            setToDate(ensureDateOrUndefined(date?.endSelectedDate));
        };

        useEffect(() => {
            setFromDate(ensureDateOrUndefined(value?.fromDate));
            setToDate(ensureDateOrUndefined(value.toDate));
        }, [value]);

        useEffect(() => {
            const startDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
            const endDate = toDate ? format(toDate, "yyyy-MM-dd") : null;

            if (value.fromDate !== startDate || value.toDate !== endDate) {
                if (startDate && endDate) {
                    onChange?.({ fromDate: startDate, toDate: endDate });
                }
            }
        }, [fromDate, toDate]);

        return (
            <div
                data-testid="calendar"
                className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9 border border-gray-200 rounded-xl overflow-hidden"
            >
                <Flex alignItems="center" justifyContent="center" className="h-11 bg-gray-50 text-gray-600">
                    <Flex alignItems="center" justifyContent="center" className="w-1/2">
                        <button
                            data-testid="calendar-prev-month-arrow"
                            type="button"
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-600 hover:text-gray-500"
                        >
                            <span className="sr-only">{t("previousMonth")}</span>
                            <ChevronLeftIcon width={24} aria-hidden="true" onClick={() => setActiveDate(subMonths(activeDate, 1))} />
                        </button>
                        <div data-testid="calendar-month" className="flex-auto text-sm font-medium">
                            {format(activeDate, "MMMM yyyy")}
                            {/* {i18next.t("intlDateTime", {
                            val: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)),
                            formatParams: {
                                val: { weekday: "long", year: "numeric", month: "long", day: "numeric" },
                            },
                        })}*/}
                        </div>
                        <button
                            data-testid="calendar-next-month-arrow"
                            type="button"
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-card-foreground hover:text-gray-500"
                        >
                            <span className="sr-only">{t("nextMonth")}</span>
                            <ChevronRightIcon width={24} aria-hidden="true" onClick={() => setActiveDate(addMonths(activeDate, 1))} />
                        </button>
                    </Flex>
                </Flex>
                <Flex className="gap-x-6 px-3">
                    <CalendarSelection
                        onChange={onDateClick}
                        currentDate={activeDate}
                        startSelectedDate={fromDate}
                        endSelectedDate={toDate}
                        isRangeSelection
                        selectableDates={selectableDates ? selectableDates : SelectableDateTypes.Future}
                        availability={availability}
                        currency={currency}
                    />
                </Flex>
            </div>
        );
    }
);
