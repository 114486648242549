import React from "react";
import { SendEvent, SendFunction } from "robot3";

import { appStateMachine } from "../services/appStateMachine";
import { IAppState } from "../types";

export type TAppStateResolver = (state: IAppState) => IAppState;

export const INITIAL_APP_STATE: IAppState = { sessionId: null, i18n: { activeLanguage: "en" } };
export interface IAppStateContext {
    state: IAppState;
    setState: React.Dispatch<React.SetStateAction<IAppState>>;
    propertyId: string | undefined;
    initialized: boolean;
    detectLanguage: () => void;
    setBookingEngineStateResolver: (fn: any) => void;

    // It seems that useStateMachine creates to instances of the appStateMachine. When the hook is used in multiple components,
    // state is not in sync anymore. When "send" is called from component A, the state of the state machine is not reflected in component B where
    // the useStateMachine hook is used as well. This is why we need to use a global state machine instance.
    requestWidgetTransition: SendFunction<SendEvent>;
    widgetState: (typeof appStateMachine)["state"];
}

export const AppStateContext = React.createContext<IAppStateContext>({
    state: INITIAL_APP_STATE,
    setState: () => {
        throw new Error("AppStateContext not ready yet");
    },
    propertyId: undefined,
    initialized: false,
    detectLanguage: () => {},
    setBookingEngineStateResolver: () => {},
    requestWidgetTransition: () => {},
    widgetState: appStateMachine.state,
});

AppStateContext.displayName = "AppStateContext";
