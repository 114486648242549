import { appHooks, appUtils, documentObserver, log, tracker } from "@app/app";
import { availabilityHooks, AvailabilityRequestType, availabilityTypes } from "@app/availability";
import { useGetCalendarAvailability } from "@app/availability/hooks";
import { bookingTypes } from "@app/booking";
import { commonHooks, commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";
import { reviewHooks, reviewTypes } from "@app/review";
import { roomHooks } from "@app/room";
import { AnalyticsAdapterPlaceholder, DocumentObserverEventTypes, GoogleAnalyticsVersions, UtilsIdentifier } from "@hotelchamp/common";
import { differenceInDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { analyticsAdapterConfigurator } from "../configurators/analyticsAdapterConfigurator";
import { useActiveBookingContext } from "../hooks";
import { analytics } from "../services/analytics";
import { history } from "../services/history";
import { languageDetector } from "../services/languageDetector";
import { IBookingEngineState } from "../types";
import { ibeConfigToUrlParams, preloadPropertyImages, stateToUrlParams, urlParamsToState } from "../utils/appUtils";
import { detectValidLanguageForProperty, mapDetectedLanguage } from "../utils/languageDetector";
import { BookingEngineStateContext, IBookingEngineStateContext } from "./BookingEngineStateContext";

export interface IBookingEngineStateProviderProps {
    children?: React.ReactNode;
}

const initialBookingId = UtilsIdentifier.uniqueString();

export const getInitialFormState = (property?: propertyTypes.IProperty): IBookingEngineState => {
    const languageDetectionResult = detectValidLanguageForProperty(languageDetector, property);

    log.info("getInitialFormState - detectValidLanguageForProperty", languageDetectionResult);

    return {
        propertyId: property?.id,
        property,
        currency: property?.settings.default_currency.code || "EUR",
        language: languageDetectionResult.resolvedLanguage,
        promoCode: undefined,
        corpCode: undefined,
        bookableItemsFilter: { type: undefined, filters: [] },
        bookingState: {
            dates: {
                fromDate: "",
                toDate: "",
            },
            bookings: [
                {
                    id: initialBookingId,
                    package: null,
                    room: null,
                    rate: null,
                    type: "room",
                    adultsCount: 2,
                    childCount: 0,
                    infantCount: 0,
                    extras: [],
                    isConfirmed: false,
                    price: undefined, // derived from the cart
                    discountedPrice: undefined, // derived from the cart
                },
            ],
        },
        loyalty: null,
        termsAccepted: false,
        activeStep: "search",
        bookingId: undefined,
        activeWarningKey: null,
    };
};

const EMPTY_EXCHANGE_RATES = {} as commonTypes.IExchangeRates;
let HAS_SET_ANALYTICS = false;
const EMPTY_AVAILABILITY: availabilityTypes.IAvailability[] = [];
let RESET_DONE = false;
const EMPTY_CALENDAR = {};
const EMPTY_REVIEWS = {} as reviewTypes.IReviewTrustyou;

export const BookingEngineStateProvider = React.forwardRef(
    ({ children }: IBookingEngineStateProviderProps, ref: React.Ref<IBookingEngineStateContext | undefined>) => {
        const {
            propertyId,
            state: appState,
            setState: setAppState,
            isInitialized: appInitialized,
            setBookingEngineStateResolver,
            isPropertyLoading,
        } = appHooks.useAppState();
        const { i18n } = useTranslation();
        const { getValues, watch, reset, setValue } = useFormContext<IBookingEngineState>();
        const { activeBookingIndex, setBookingIds } = useActiveBookingContext();
        const [selectedRate] = React.useState<commonTypes.IRate | undefined>();
        const [cart, setCart] = React.useState<bookingTypes.ICart>();
        const [isCartInitialized, setIsCartInitialized] = useState(false);
        const [trackerReady, setTrackerReady] = React.useState<boolean>(false);
        const property = appState.property;
        const { data: reviews = EMPTY_REVIEWS } = reviewHooks.useGetReview(property?.branch_hash || "");

        const initialFormState = getInitialFormState(property);

        const urlParams = new URLSearchParams(history.location.search);
        const searchParams = urlParams.toString();
        const initSearch = !!appState.initConfig ? ibeConfigToUrlParams(appState.initConfig).toString() : searchParams;
        const activeStep = urlParams.get("c");

        const state = watch();

        log.debug("ibe state ", state);

        const [searchFromDate, searchToDate] = (urlParams.get("d") || "").split(",");
        const dates = state.bookingState?.dates;
        const fromDate = dates?.fromDate || searchFromDate;
        const toDate = dates?.toDate || searchToDate;
        const defaultLanguage = initialFormState.language;

        const { fromDate: start, toDate: end } = appUtils.calendarRange;
        const { data: calendarAvailability = EMPTY_CALENDAR } = useGetCalendarAvailability(propertyId || "", start, end, {
            enabled: Boolean(propertyId),
        });

        const getAvailabilityParams = (rt?: AvailabilityRequestType) => appUtils.stateToAvailabilitySearchParams(state, rt).toString();

        const { data: availability = EMPTY_AVAILABILITY, isLoading: loadingAvailability } = availabilityHooks.useGetAvailability(
            propertyId,
            appUtils.getAvailabilitySearch(urlParams).toString(),
            {
                enabled: !!propertyId && !!fromDate && !!toDate,
            }
        );

        const { data: exchangeRates = EMPTY_EXCHANGE_RATES } = commonHooks.useGetExchangeRates(propertyId || "", { enabled: !!propertyId });

        /**
         * The following fetches the extras for the selected rooms and rates.
         * We need first to parse the booking room/rate from URL params and then fetch the extras.
         */
        const bookingRoomRates = appUtils.getBookingRoomRate(searchParams, availability);
        const gotRoomRates = !!bookingRoomRates.length && !!bookingRoomRates.some((r) => !!r.length);
        const bookingLanguage = mapDetectedLanguage(urlParams.get("lg") || defaultLanguage);
        const bookingExtrasQueries = roomHooks.useGetMultipleRoomRateExtras(propertyId || "", bookingRoomRates, bookingLanguage, {
            enabled: !!propertyId && !!bookingLanguage && gotRoomRates,
        });
        const loadingExtras = bookingExtrasQueries.some((query) => query.isLoading);
        const bookingExtras: commonTypes.IExtra[][] = bookingExtrasQueries.map((q) => q.data || []);
        const extrasFetched = !appUtils.bookingHasExtras(searchParams) || bookingExtrasQueries.every((q) => q.isFetched && !q.isLoading);

        const isLoading = isPropertyLoading || loadingAvailability || bookingExtrasQueries.some((q) => q.isLoading);

        // Responsible to apply persisted sarch params if they exist
        useEffect(() => {
            if (appInitialized) {
                if (appState.initConfig && !appState.searchParams) {
                    RESET_DONE = false;

                    history.push({ pathname: window.location.pathname, search: initSearch.toString() });

                    setAppState((oldState) => ({ ...oldState, initConfig: undefined, searchParams: initSearch.toString() }));
                } else if (!appState.initConfig && window.location.search) {
                    const mergedSearchParams = appUtils.mergeSearchParams(
                        new URLSearchParams(appState.searchParams || ""),
                        new URLSearchParams(window.location.search)
                    );

                    history.push({ pathname: window.location.pathname, search: mergedSearchParams.toString() });
                } else if (appState.searchParams && !RESET_DONE) {
                    history.push({ pathname: window.location.pathname, search: appState.searchParams });
                }

                documentObserver.on(DocumentObserverEventTypes.UrlChange, () => {
                    const storedSearchParams = appState.searchParams;

                    if (storedSearchParams && !window.location.search) {
                        history.push({ pathname: window.location.pathname, search: storedSearchParams });
                    }
                });
            }
        }, [appState, appInitialized]);

        // Responsible for keeping track of active step
        useEffect(() => {
            if (activeStep) {
                setValue("activeStep", activeStep);
            }
        }, [activeStep, setValue]);

        // Responsible for listening to AppState language changes and applying them to ibe state
        useEffect(() => {
            if (appState?.language) {
                setValue("language", appState.language);
            }
        }, [appState?.language, setValue]);

        useEffect(() => {
            setBookingEngineStateResolver(getValues);

            return () => setBookingEngineStateResolver(null);
        }, [getValues]);

        // Responsible for resetting the ibe state from URL Search params
        useEffect(() => {
            if (property && !isLoading && appInitialized && extrasFetched && !RESET_DONE) {
                if (initSearch && initSearch.includes("br=")) {
                    const newState = urlParamsToState(initSearch, property, availability, bookingExtras, calendarAvailability);

                    if (newState) {
                        RESET_DONE = true;
                        reset(newState);
                    }
                } else {
                    RESET_DONE = true;
                    reset(getInitialFormState(property));
                }
            }
        }, [property, availability, extrasFetched, bookingExtras, appInitialized]);

        // Responsible for updating URL Search params as ibe state changes
        useEffect(() => {
            const subscription = watch((newState: any) => {
                if (extrasFetched && RESET_DONE && newState.bookingState?.dates?.fromDate && newState.bookingState?.dates?.toDate) {
                    const { pathname, search } = history.location;
                    const currentParams = new URLSearchParams(search);
                    const currentStep = currentParams.get("c");
                    const newSearch = stateToUrlParams(newState);
                    const newParams = new URLSearchParams(newSearch);

                    const isChildChanged = newParams.get("c") && newParams.get("c") !== currentStep;
                    const wasChildNavigatable = activeStep !== "thank-you";
                    const navigateFn = isChildChanged && wasChildNavigatable ? history.push : history.replace;

                    newParams.set("p", "base");
                    newParams.set("c", currentStep || "search");

                    // Persist the new search params (serialized ibe state) to local storage
                    setAppState((oldState) => ({ ...oldState, initConfig: undefined, searchParams: newParams.toString() }));

                    const mergedParams = appUtils.mergeSearchParams(currentParams, newParams);

                    navigateFn({ pathname, search: mergedParams.toString() });
                }
            });

            return () => subscription.unsubscribe();
        }, []);

        const filteredBookableItems = React.useMemo(() => {
            const filter = state.bookableItemsFilter;
            const rooms = availability[activeBookingIndex]?.rooms || [];
            const packages = availability[activeBookingIndex]?.packages || [];
            const sort = property?.settings.sort_order || "rp";
            const items = sort === "rp" ? [...rooms, ...packages] : [...packages, ...rooms];

            let filteredItems = items.filter(
                (item) => item.bookable_type === filter.type || filter.type === undefined || filter.type === "rp"
            );

            if (filter?.filters.length) {
                filteredItems = filteredItems.filter((el) => filter.filters.every((f) => el.filters.includes(f)));
            }

            return filteredItems;
        }, [state.bookableItemsFilter, availability, property, activeBookingIndex]);

        useEffect(() => {
            if (!propertyId) {
                log.error("Error, no property id configured!");
            } else {
                log.info(`Property id ${propertyId} activated`);
            }
        }, [propertyId]);

        useEffect(() => {
            if (cart) {
                setAppState((oldState) => ({ ...oldState, cart }));
            }
        }, [cart]);

        // responsible to set and update property in tracker
        useEffect(() => {
            if (property) {
                tracker.setProperty(property);

                setTrackerReady(true);
            }
        }, [property]);

        // responsible to preload rooms (or other) property related images
        useEffect(() => {
            if (property) {
                preloadPropertyImages(property, { primaryOnly: true, mode: "room" });
            }
        }, [property]);

        // Responsible for ensuring the selected booking package/room/rate are kept in sync with fresh availability data
        useEffect(() => {
            if (availability.length) {
                (state.bookingState?.bookings || []).forEach((b, i) => {
                    const freshPackage = availability[i].packages.find((p) => p.id === b.package?.id);
                    const freshRoom = availability[i].rooms.find((r) => r.id === b.room?.id);
                    const freshRate = (freshRoom?.rates || []).find((rate) => rate.id === b.rate?.id);

                    setValue(`bookingState.bookings.${i}.package`, freshPackage || b.package);
                    setValue(`bookingState.bookings.${i}.room`, freshRoom || b.room);
                    setValue(`bookingState.bookings.${i}.rate`, freshRate || b.rate);
                });
            }
        }, [availability, setValue]);

        // responsible to set analytics adapter
        useEffect(() => {
            if (property && !HAS_SET_ANALYTICS) {
                const placeholderAdapter = analytics.getAdapter() as AnalyticsAdapterPlaceholder;

                const trackingConfig =
                    property?.settings.tracking ||
                    // eslint-disable-next-line no-underscore-dangle
                    (window as any)?._hcTrackingConfig ||
                    JSON.parse(localStorage.getItem("__hc_ibe__.hcTrackingConfig") || "{}");
                const activeTrackingMethodConfig = trackingConfig?.gtm || trackingConfig?.ga;
                const hasActiveTrackingMethodConfig = !!activeTrackingMethodConfig;
                const configuredGaVersion = hasActiveTrackingMethodConfig
                    ? !!trackingConfig?.gtm
                        ? GoogleAnalyticsVersions.GoogleTagManager
                        : GoogleAnalyticsVersions.Gtag
                    : process.env.NODE_ENV === "production"
                    ? null
                    : GoogleAnalyticsVersions.GoogleTagManager;

                if (configuredGaVersion) {
                    placeholderAdapter.setImplementation(
                        analyticsAdapterConfigurator({
                            googleAnalytics: {
                                // dimension: 2,
                                configuredGaVersion,
                            },
                        })
                    );

                    // the placeholder adapter can only be replaced once since all events are collected in the placeholder
                    // until the 'real' instance is set. Once set, all collected events are processed
                    HAS_SET_ANALYTICS = true;

                    log.info(`Analytics "${configuredGaVersion}" has been initialised`);
                } else {
                    log.warn("Could not enable tracking because no config provided for one of the methods gtm or ga");
                }
            }
        }, [property]);

        useEffect(() => {
            i18n.changeLanguage(state.language, (err) => {
                if (err) {
                    log.error("change lang error", err);
                }
            });
        }, [state.language]);

        useEffect(() => {
            fetchCart().then((storedCartState) => {
                if (Object.keys(storedCartState).length) {
                    setCart(storedCartState);
                }

                setIsCartInitialized(true);
            });
        }, [appInitialized]);

        useEffect(() => {
            if (state.bookingState?.bookings.length) {
                const nextBookingIds = [...state.bookingState.bookings].map((b) => b.id);

                setBookingIds(nextBookingIds);
            }
        }, [state.bookingState?.bookings]);

        /**
         * Responsible for checking booking price changes and setting the warning key to display the corresponding warning modal.
         * NOTE: Below logic is disabled till we get further analysis from Product Team.
         */
        // useEffect(() => {
        //     if (["summary", "checkout"].includes(state.activeStep)) {
        //         fetchCart().then((storedCart: bookingTypes.ICart | undefined) => {
        //             const los = getLengthOfStay();

        //             if (storedCart && state.bookingState?.bookings?.length) {
        //                 for (let i = 0; i < storedCart.rooms.length; i++) {
        //                     const cartRoom = (storedCart.rooms || []).filter((r) => r.room_id && r.rate_id)[i];
        //                     if (cartRoom) {
        //                         const sameRoom = cartRoom.room_id === state.bookingState?.bookings[i].room?.id;
        //                         const sameRate = cartRoom.rate_id === state.bookingState?.bookings[i].rate?.id;

        //                         if (sameRoom && sameRate && los && bookingAvailabilityCheck) {
        //                             const oldRatePrice = Number(cartRoom.total) / los;
        //                             const freshPrice = bookingAvailabilityCheck.availability[i].price;

        //                             if (freshPrice !== undefined && oldRatePrice !== freshPrice) {
        //                                 setValue("activeWarningKey", oldRatePrice < freshPrice ? "higherPrice" : "lowerPrice");
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         });
        //     }
        // }, [state.activeStep, state.bookingState?.bookings, bookingAvailabilityCheck]);

        const toggleExtra = (extra: commonTypes.IExtra) => {
            const bookings = getValues("bookingState.bookings") || [];
            const targetBooking = bookings[activeBookingIndex];

            const extras = targetBooking?.extras || [];
            const selection =
                extras.findIndex((el) => el.id === extra.id) !== -1 ? extras.filter((el) => el.id !== extra.id) : [...extras, extra];

            setValue(`bookingState.bookings.${activeBookingIndex}.extras`, selection);
        };

        const getLengthOfStay = () => {
            const validDates = fromDate && toDate;

            return validDates ? differenceInDays(new Date(toDate), new Date(fromDate)) : 0;
        };

        const fetchCart = async (): Promise<bookingTypes.ICart> => appState.cart || ({} as bookingTypes.ICart);

        const getExtraById = (id: string) => (property?.extras || []).find((e) => e.id === id);
        const getBookableItems = () => filteredBookableItems;
        const getTotalPrice = ({ discounted = true }: { discounted?: boolean }): number =>
            Number(discounted ? cart?.discounted_total : cart?.total);

        const resetBooking = () => {
            RESET_DONE = true;
            // Reset params
            const params = new URLSearchParams();
            params.set("p", "base");
            params.set("c", "search");

            setValue("bookingId", undefined);

            history.replace({ pathname: window.location.pathname, search: params.toString() });

            // Reset state
            reset(getInitialFormState(property));
        };

        const clearUrlParams = () => {
            const params = new URLSearchParams(stateToUrlParams(state));
            params.forEach((v, k) => params.delete(k));

            history.push({ pathname: window.location.pathname, search: params.toString() });
        };

        const api: IBookingEngineStateContext = {
            getState: getValues,
            resetBooking: React.useCallback(resetBooking, [resetBooking]),
            getTotalPrice: React.useCallback(getTotalPrice, [getTotalPrice]),
            toggleExtra: React.useCallback(toggleExtra, [toggleExtra]),
            getLengthOfStay: React.useCallback(getLengthOfStay, [getLengthOfStay]),
            getExtraById: React.useCallback(getExtraById, [getExtraById]),
            getBookableItems: React.useCallback(getBookableItems, [getBookableItems]),
            getAvailabilityParams: React.useCallback(getAvailabilityParams, [getAvailabilityParams]),
            setCart: React.useCallback(setCart, [setCart]),
            fetchCart: React.useCallback(fetchCart, [fetchCart]),
            clearUrlParams: React.useCallback(clearUrlParams, [clearUrlParams]),
            calendarAvailability,
            availability,
            bookingExtras,
            loadingExtras,
            filteredBookableItems,
            isLoading,
            isCartInitialized,
            unconfirmedRate: selectedRate,
            cart,
            property,
            exchangeRates: exchangeRates || [],
            initialized: RESET_DONE,
            reviews,
            trackerReady,
        };

        return <BookingEngineStateContext.Provider value={api}>{children}</BookingEngineStateContext.Provider>;
    }
);
