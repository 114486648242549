import { AvailabilityRequestType, availabilityTypes } from "@app/availability";
import { bookingTypes } from "@app/booking";
import { BookableType, calendarUtils, commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";
import { UtilsDom, UtilsIdentifier } from "@hotelchamp/common";
import { addMonths, differenceInDays, format } from "date-fns";

import { shadowRoot } from "../../main";
import { config as appConfig } from "../config";
import { getInitialFormState } from "../context/BookingEngineStateProvider";
import { IBookingEngineState, IIbeConfig } from "../types";

// const encoded = {
//     d: "2023-09-19,2023-09-21", // dates fromDate,toDate
//     br: "2435816bas,2435816b,2433216b,24358133,1,0,0|24358121", // id,packageId,roomId,rateId,adultsCount,childCount,infantCount|extra_1
// };

const datesRegex = /\d{4}-\d{2}-\d{2},\d{4}-\d{2}-\d{2}/;
const bookingRegex =
    /^[a-zA-Z0-9_]{10},([a-zA-Z0-9]{12}|_),([a-zA-Z0-9]{12}|_),([a-zA-Z0-9]{12}|_),\d+,\d+,\d+(?:\|[a-zA-Z0-9]{12}(?:,[a-zA-Z0-9]{12})*)?$/;

/**
 * resolvePropertyId
 * Simple method to resolve property id from global scope
 * @returns
 */
export const resolvePropertyId = () => ((window as any)[appConfig.globalVendorNamespace] as any)?.ibe?.propertyId;

/**
 * resolvePropertyIdOrFail
 * @see - resolvePropertyId
 * Will throw an Error once property could not be resolved
 * @returns
 */
export const resolvePropertyIdOrFail = () => {
    const propertyId = resolvePropertyId();

    if (!propertyId) {
        throw new Error("Could not resolve propertyId");
    }

    return propertyId;
};

export const stateToUrlParams = (state: IBookingEngineState): string => {
    const bookingState = state.bookingState || {};
    const params = new URLSearchParams();

    if (!Object.keys(bookingState).length) {
        return params.toString();
    }

    if (state.language) {
        params.set("lg", state.language);
    }

    if (state.currency) {
        params.set("cu", state.currency);
    }

    if (state.promoCode) {
        params.set("pc", state.promoCode);
    } else {
        params.delete("pc");
    }

    if (state.corpCode) {
        params.set("cc", state.corpCode);
    } else {
        params.delete("cc");
    }

    if (state.bookingId) {
        params.set("id", state.bookingId);
    } else {
        params.delete("id");
    }

    if (bookingState.dates?.fromDate && bookingState.dates?.toDate) {
        params.set("d", `${bookingState.dates.fromDate},${bookingState.dates.toDate}`);
    }

    (bookingState.bookings || []).forEach((b, i) => {
        const packageId = getShortId(b.package?.id || "") || "_";
        const roomId = getShortId(b.room?.id || "") || "_";
        const rateId = getShortId(b.rate?.id || "") || "_";

        let br = `${b.id},${packageId},${roomId},${rateId},${b.adultsCount},${b.childCount},${b.infantCount}`;

        if (b.extras.length) {
            br = `${br}|${b.extras.map((e) => getShortId(e.id)).join(",")}`;
        }

        params.append("br", br);
    });

    setFilterUrlParams(params, state.bookableItemsFilter);

    return params.toString();
};

export const getAvailabilitySearch = (search: URLSearchParams, requestType?: AvailabilityRequestType): URLSearchParams => {
    const params = new URLSearchParams();
    params.set("rt", String(requestType || AvailabilityRequestType.Get));
    const [ci, co] = (search.get("d") || "").split(",");
    params.set("ci", ci);
    params.set("co", co);
    const pc = search.get("pc");
    const cc = search.get("cc");
    if (pc) {
        params.set("pc", pc);
    }
    if (cc) {
        params.set("cc", cc);
    }

    let bookingRooms = search.getAll("br");

    if (!bookingRooms.length) {
        bookingRooms = ["2435816bas,_,_,_,2,0,0"];
    }

    bookingRooms.forEach((br, i) => {
        const [bookings] = br.split("|");
        const props = (bookings || "").split(",");

        params.set(`rs[${i}][id]`, props[0]);
        params.set(`rs[${i}][ac]`, props[4]);
        params.set(`rs[${i}][cc]`, props[5]);
    });

    return params;
};

export const stateToAvailabilitySearchParams = (state: IBookingEngineState, requestType?: AvailabilityRequestType): URLSearchParams => {
    const bookingState = state.bookingState;
    const params = new URLSearchParams();

    params.set("rt", String(requestType || AvailabilityRequestType.Get));

    if (bookingState?.dates?.fromDate && bookingState?.dates?.toDate) {
        params.set("ci", bookingState.dates.fromDate);
        params.set("co", bookingState.dates.toDate);
    }

    if (state.promoCode) {
        params.set("pc", state.promoCode);
    }

    if (state.corpCode) {
        params.set("cc", state.corpCode);
    }

    if (requestType && requestType > 0) {
        params.set("lg", state.language);
        params.set("cu", state.currency);
    }

    (bookingState?.bookings || []).forEach((b, i) => {
        params.set(`rs[${i}][id]`, b.id);
        params.set(`rs[${i}][ac]`, b.adultsCount.toString());
        params.set(`rs[${i}][cc]`, b.childCount.toString());

        if (requestType && requestType > 0 && b.rate?.id && b.room?.id) {
            params.set(`rs[${i}][ra]`, b.rate.id.toString());
            params.set(`rs[${i}][ro]`, b.room.id.toString());

            b.extras.forEach((extra, j) => {
                params.set(`rs[${i}][ex][${j}]`, extra.id);
            });
        }
    });

    return params;
};

export const urlParamsToState = (
    params: string,
    property: propertyTypes.IProperty | undefined,
    availability: availabilityTypes.IAvailability[],
    extras: commonTypes.IExtra[][],
    calendarAvailability: availabilityTypes.ICalendarAvailability
): IBookingEngineState | null => {
    const q = new URLSearchParams(params);

    if (!property || !availability.length) {
        return null;
    }

    const initialState = getInitialFormState(property);

    if (!validUrlParams(q)) {
        return initialState;
    }

    const [fromDate, toDate] = (q.get("d") || "").split(",");

    const availableDates = calendarAvailability ? calendarUtils.availableDateRange(calendarAvailability, fromDate, toDate) : true;
    const language = q.get("lg") || initialState.language;

    const state: IBookingEngineState = {
        ...initialState,
        bookableItemsFilter: urlParamsToFilterState(q, property),
        language,
        currency: q.get("cu") || initialState.currency,
        promoCode: q.get("pc") || initialState.promoCode,
        corpCode: q.get("cc") || initialState.corpCode,
        bookingState: {
            bookings: initialState.bookingState?.bookings || [],
            dates: { fromDate, toDate },
        },
        bookingId: q.get("id") || undefined,
        activeStep: q.get("c") || initialState.activeStep,
        activeWarningKey: !availableDates ? "date" : null,
    };

    q.getAll("br").forEach((br, i) => {
        const [bookings, extraIds] = br.split("|");
        const props = (bookings || "").split(",");
        const type = props[1] === "_" ? "room" : "package";
        const foundPackage = (availability[i]?.packages || []).find((p) => matchShortId(p.id, props[1])) || null;
        const foundRoom = (availability[i]?.rooms || []).find((r) => matchShortId(props[2], r.id)) || null;
        const foundRate = getRateByShortId(type === "room" ? foundRoom : foundPackage, props[3]) || null;
        const selectedExtras = getSelectedExtras(extras[i], (extraIds || "").split(","));

        state.bookingState.bookings[i] = {
            id: props[0],
            type,
            package: foundPackage,
            room: foundRoom,
            rate: foundRate,
            adultsCount: Number(props[4]),
            childCount: Number(props[5]),
            infantCount: Number(props[6]),
            isConfirmed: Number(props[7]) === 1 ? true : false,
            extras: selectedExtras,
            price: undefined,
            discountedPrice: undefined,
        };

        const roomWarning = props[2] !== "_" && !foundRoom;
        const rateWarning = props[3] !== "_" && !foundRate;

        state.activeWarningKey = roomWarning ? "room" : rateWarning ? "rate" : state.activeWarningKey;
    });

    return state;
};

export const getBookingRoomRate = (params: string, availability: availabilityTypes.IAvailability[]) => {
    if (!params || !availability.length) {
        return [];
    }

    const q = new URLSearchParams(params);

    return q.getAll("br").map((br, i) => {
        const [bookings] = br.split("|");
        const props = (bookings || "").split(",");
        const type = props[1] === "_" ? "room" : "package";
        const foundPackage = (availability[i]?.packages || []).find((p) => matchShortId(p.id, props[1])) || null;
        const foundRoom = (availability[i]?.rooms || []).find((r) => matchShortId(props[2], r.id)) || null;
        const foundRate = getRateByShortId(type === "room" ? foundRoom : foundPackage, props[3]) || null;

        return foundRoom && foundRate ? [foundRoom.id, foundRate.id] : [];
    });
};

export const getRateByShortId = (
    item: availabilityTypes.IRoomAvailability | availabilityTypes.IPackageAvailability | null,
    shortId: string
): availabilityTypes.IRateAvailability | undefined =>
    item?.bookable_type === BookableType.Room
        ? (item?.rates || []).find((r) => matchShortId(shortId, r.id))
        : (item?.rooms[0]?.rates || []).find((r) => matchShortId(shortId, r.id));

export const getSelectedExtras = (extras: commonTypes.IExtra[], extraShortIds: string[]) =>
    (extras || []).filter((e) => extraShortIds.includes(getShortId(e.id) || ""));

export const bookingHasExtras = (params: string): boolean => {
    const q = new URLSearchParams(params);

    return q.getAll("br").some((br) => br.includes("|"));
};

export const urlParamsToFilterState = (
    search: URLSearchParams,
    property: propertyTypes.IProperty | undefined
): commonTypes.IBookableItemsFilter => {
    if (!property) {
        return { type: undefined, filters: [] };
    }
    const queryFilterType = search.get("f[type]");
    const type =
        queryFilterType === "_" || !queryFilterType
            ? undefined
            : queryFilterType === "rp"
            ? "rp"
            : queryFilterType === "r"
            ? BookableType.Room
            : BookableType.Package;

    const filters = !!search.get("f[filters]")
        ? (search.get("f[filters]") || "").split(",").map((id) => getFullId(id, "filter", property) || id)
        : [];

    return {
        type,
        filters,
    };
};

export const setFilterUrlParams = (params: URLSearchParams, filter: commonTypes.IBookableItemsFilter) => {
    if (filter) {
        const type = filter.type === undefined ? null : filter.type === "rp" ? "rp" : filter.type === BookableType.Room ? "r" : "p";

        if (type) {
            params.set("f[type]", type);
        }

        if (filter.filters.length) {
            params.set("f[filters]", filter.filters.map((id) => getShortId(id)).join(","));
        }
    }
};

export const matchShortId = (shortId: string, uuid: string): boolean => uuid.split("-")[4] === shortId;

export const getShortId = (id: string | null): string | null => {
    if (!id) {
        return null;
    }

    if (id.length < 8) {
        throw new Error("getShortId is used with valid UUIDs only");
    }

    return id.split("-")[4];
};

export const getFullId = (
    shortId: string,
    type: "room" | "package" | "rate" | "extra" | "filter",
    property: propertyTypes.IProperty
): string | null => {
    const key = `${type}s`;

    if (!shortId) {
        return null;
    }

    if (type === "filter") {
        return property.filter_groups.flatMap((fg) => fg.filters).find((f) => matchShortId(shortId, f.id))?.id || null;
    }

    return property[key].map((r: any) => r.id).find((id: string) => matchShortId(shortId, id)) || null;
};

export const validUrlParams = (q: URLSearchParams): boolean => {
    const regexMap: { [key: string]: RegExp } = {
        d: datesRegex,
        br: bookingRegex,
    };

    for (const key of q.keys()) {
        if (key in regexMap) {
            for (const value of q.getAll(key)) {
                if (!regexMap[key].test(value)) {
                    return false;
                }
            }
        }
    }

    return true;
};

export const calendarRange = {
    fromDate: format(new Date(), "yyyy-MM-dd"),
    toDate: format(addMonths(new Date(), 6), "yyyy-MM-dd"),
};

interface IPreloadPropertyImagesOptions {
    primaryOnly?: boolean;
    mode?: "all" | "package" | "room";
}

export const preloadPropertyImages = (
    property: propertyTypes.IProperty,
    { primaryOnly = true, mode = "room" }: IPreloadPropertyImagesOptions = {}
) => {
    const collectedPrimaryImages: commonTypes.IImage[] = [];
    const preloadContainerElement = shadowRoot;

    const collectImages = (items: propertyTypes.IProperty["rooms"] | propertyTypes.IProperty["packages"]) => {
        items.forEach((item) => {
            if (item?.images?.length) {
                if (primaryOnly) {
                    collectedPrimaryImages.push(item.images[0]);
                } else {
                    item?.images.forEach((image) => collectedPrimaryImages.push(image));
                }
            }
        });
    };

    if (["all", "room"].includes(mode)) {
        collectImages(property.rooms);
    }

    if (["all", "package"].includes(mode)) {
        collectImages(property.packages);
    }

    const existingPreloadedImageFilenames = Array.from(document.querySelectorAll<HTMLLinkElement>("hc-ibe-preload-img")).map(
        (link) => link.href
    );

    collectedPrimaryImages.forEach((image) => {
        if (!existingPreloadedImageFilenames.includes(image.path)) {
            UtilsDom.createElement(preloadContainerElement as any, "link", {
                rel: "preload",
                href: image.path,
                as: "image",
                id: `hc-ibe-preload-img-${image.id}`,
                class: "hc-ibe-preload-img",
            });
        }
    });
};

export const reservedBookingToCart = (reservedBooking: bookingTypes.IReservedBooking): bookingTypes.ICart => ({
    total: String(reservedBooking.total),
    discounted_total: String(reservedBooking.discounted_total),
    fees: reservedBooking.cart_items.filter((item) => item.key.includes("res-fee")),
    taxes: reservedBooking.cart_items.filter((item) => item.key.includes("tax")),
    rooms: reservedBooking.cart_items.filter((item) => item.key.includes("rate")),
    discounts: reservedBooking.cart_items.filter((item) => item.key.includes("promocode")),
    extras: reservedBooking.cart_items.filter((item) => item.key.includes("supplement")),
    refundable: reservedBooking.refundable,
    refund_amount: reservedBooking.refund_amount,
    cancellation_within_window: reservedBooking.cancellation_within_window,
});

export const ibeConfigToUrlParams = (config: IIbeConfig): URLSearchParams => {
    const params = new URLSearchParams();

    params.set("p", "base");

    const { checkin, checkout, adultCount, childCount, roomCount, promoCode, languageCode } = config;

    if (checkin && checkout) {
        params.set("d", `${format(checkin, "yyyy-MM-dd")},${format(checkout, "yyyy-MM-dd")}`);
    }

    for (let i = 0; i < (roomCount || 1); i++) {
        params.append("br", `${UtilsIdentifier.uniqueString()},_,_,_,${adultCount || 2},${childCount || 0},0`);
    }

    if (promoCode) {
        params.set("pc", promoCode);
    }

    if (languageCode) {
        params.set("lg", languageCode);
    }

    if (checkin && checkout && adultCount) {
        params.set("c", "availability");
    } else {
        params.set("c", "search");
    }

    return params;
};

export const ibeStateToTrackingSystemBooking = (
    state: IBookingEngineState,
    gd: bookingTypes.IGuestDetails | null,
    cart: bookingTypes.ICart | null
) => {
    const arrivalDate = new Date(state.bookingState.dates.fromDate);
    const departureDate = new Date(state.bookingState.dates.toDate);
    const bookedRoom = state.bookingState.bookings[0].room;

    return {
        arrivalDate,
        departureDate,
        adultCount: state.bookingState.bookings[0].adultsCount,
        childCount: state.bookingState.bookings[0].childCount,
        babyCount: state.bookingState.bookings[0].infantCount,
        stayNights: differenceInDays(new Date(departureDate), new Date(arrivalDate)),
        currencyCode: state.currency,
        roomCount: state.bookingState.bookings.length,
        rateValues: bookedRoom
            ? {
                  min: +Number(bookedRoom?.min_rate).toFixed(2),
                  avg: (Number(bookedRoom?.min_rate) + Number(bookedRoom?.max_rate)) / 2,
                  max: +Number(bookedRoom?.max_rate).toFixed(2),
              }
            : null,
        roomsRates: null, // TODO: check whether we still need this
        bookingPrice: Number(cart?.total) || null,
        promoCode: state.promoCode,
        email: gd?.email || null,
        languageCode: state.language,
        roomType: state.bookingState.bookings[0].room?.name || null,
        roomRateType: state.bookingState.bookings[0].rate?.name || null,
        upsellProducts: null,
        isRoomAvailability: true,
    };
};

export const isIbeParam = (key: string) => ["d", "lg", "cu", "pc", "cc", "br", "p", "c", "id"].includes(key);

export const mergeSearchParams = (params1: URLSearchParams, params2: URLSearchParams): URLSearchParams => {
    const mergedParams = new URLSearchParams();

    // Add all params from params1 to the mergedParams
    params1.forEach((value, key) => {
        // If params2 doesn't have this key and this key is not an ibe param key, retain it from params1
        // If the param is an ibe param and was removed, we don't want to add it again.
        if (!params2.has(key) && !isIbeParam(key)) {
            mergedParams.append(key, value);
        }
    });

    // Add all params from params2 to the mergedParams, replacing any existing keys
    params2.forEach((value, key) => {
        mergedParams.append(key, value); // Add or overwrite existing values
    });

    return mergedParams;
};
